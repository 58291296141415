(function () {
    angular.module('informaApp')
        .service('AuthenticationService', AuthenticationService);

    function AuthenticationService($rootScope, BaseApiService, ConstantsSvc, $cookies) {
        let currentUser = null;
        let fetchingUser = false;
        let authenticationInitiated = false;

        return {
            isLoggedIn: () => {
                return !!$cookies.get(ConstantsSvc.auth.tokenCookieName);
            },

            isAdmin: () => {
                return currentUser.isAdmin;
            },

            generateAuthenticationUrl(backUrl) {
                const query = !backUrl ? '' : `?back=${backUrl}`;
                authenticationInitiated = true;
                return BaseApiService.get('auth/urls/login' + query)
                    .then(response => response.data.authUrl)
            },

            generateEmailVerificationUrl() {
                return BaseApiService.get('auth/urls/email-verification')
                    .then(response => response.data.emailVerificationUrl)
            },

            generateLogoutUrl() {
                const logoutUrlWithoutSlash = ConstantsSvc.urls.logout.slice(1);
                const url = ConstantsSvc.DOMAIN + logoutUrlWithoutSlash;

                return BaseApiService.get(`auth/urls/logout`)
                    .then(response => `${response.data.logoutUrl}&returnTo=${window.envVariables.authzero.logoutredirect}`);
            },

            loadUser(caller) {
                if (caller === 'layout' && !(!!$cookies.get(ConstantsSvc.auth.tokenCookieName))) {
                    return true;
                }
                
                if (!currentUser && !fetchingUser) {
                    fetchingUser = true;
                    return BaseApiService.get('users/current')
                        .then(response => {
                            this.setUser(response.data);
                            return true;
                        })
                        .catch(() => false)
                        .finally(() => {
                            fetchingUser = false;
                        });
                }
            },

            getCurrentUser() {
                const user = _.clone(currentUser);

                if (user) {
                    user.hasAccess = user.active && user.isEmailVerified;
                }
                
                return user;
            },

            onCurrentUserUpdated(callback) {
                this._onCurrentUserUpdatedCallback = callback;
            },

            setUser(user) {
                currentUser = user;
                if (this._onCurrentUserUpdatedCallback) {
                    this._onCurrentUserUpdatedCallback(currentUser);
                }
            },

            logout() {
                $cookies.remove(ConstantsSvc.auth.tokenCookieName);
            },

            login(code) {
                return BaseApiService.post('auth', {code})
                .then(authResult => {
                    if (authResult && authResult.data.token) {
                        setCookies(authResult.data.token, ConstantsSvc, $cookies);
                        return this.loadUser('login');
                    }
                    return false;
                })
                .then(isSuccess => { 
                    if (isSuccess) {
                        const isValidUser = this.getCurrentUser();
                        if (isValidUser) {
                            return isValidUser;
                        }
                    }
                    return null;
                })
                .catch(() => {
                    this.logout();
                });
            }
        };
    }

    function setCookies(token, ConstantsSvc, $cookies) {
        const expires = new Date();
        expires.addHours(ConstantsSvc.auth.cookiesExpirationHours);

        $cookies.put(ConstantsSvc.auth.tokenCookieName, token, {expires});
    }
})();
